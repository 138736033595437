:root {
  --bg-color: #f9f9f9;
  --primary-color: #000000E9;
  --secondary-color: #000000B0; 
  --highlight: #660000E9;
}

.App {
  text-align: center;
}

.Divider {
  color: var(--highlight);
  font-size: 1.25rem;
}
.Social {
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin: auto;
  margin-bottom: 1em;

}
.Social a {
  color: var(--primary-color);
}

.Endorsement {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row, wrap;
  max-width: 400px;
  margin: auto;
}
.Endorsement a {
  font-variant: none;
  max-width: 168px;
  flex: 0 0 2;
  color: var(--primary-color);
}

.Badge {
  max-height: 7em;
  flex: 0 1 1;
}

.Fade {
  transition: opacity 5s;
  opacity: 1;
}

.Navigation {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-flow: row, wrap;
  list-style-type: none;
  max-width: 30em;
  margin: auto;
  margin-top: 10vh;
  text-decoration: none;
  font-weight: 900;
}

.Navigation a {
    font-variant: normal;
    text-decoration: none;
    color: var(--primary-color);
    font-size: 1.5em;
    transition: color .7s ease;
}

.Navigation a:hover {
  color: var(--highlight);
  transition: color .7s ease;
}
.Navigation .selected {
  border-bottom: solid var(--highlight);
  transition: .7s .1s border linear;
}

.Skill {
  padding: .1em;
}

.Projects {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
}

.Project {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 2em;
  border: 1px solid var(--secondary-color);
  padding: 10px;
  max-width: 300px;
  border-radius: 5px;
}

.App-header {
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #222;
}

