* {
  padding: 0;
  margin: 0;
  font-size: 24px;
  color: var(--primary-color);
}

h1 {
  font-size: calc(24px + 4vw);
  margin-bottom: -.3em;
}

h2 {
  font-size: calc(16px + 3vw);
  color: var(--highlight);
}

h4 {
  font-variant: small-caps;
  font-size: 1.44rem;
  color: var(--highlight);
}

h5 {
  border-bottom: solid var(--highlight);
  margin: .3em;
  margin-top: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  width: 80vw;
  margin: 2em auto .5em;
  color: var(--secondary-color);
}

a {
  text-decoration: none;
  color: var(--highlight);
}

p {
  max-width: 70vw;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
